import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from 'src/components/Box';
import { CheckListItem } from 'src/components/CheckListItem';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { WhatWeOffer } from 'src/components/Methods/maintain/WhatWeOffer';
import { MethodsCTA } from 'src/components/Methods/MethodsCTA';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';

const subnav = [
    {
        id: 'features',
        label: 'Features',
    },
    {
        id: 'plans',
        label: 'Plans',
    },
    {
        id: 'project-management',
        label: 'Project Management',
    },
];

export default function maintain({ data }) {
    const flairColor = '#E251AB';
    return (
        <Layout
            pageTitleFull="Maintain and expand your online community with Audentio"
            siteDescription="Let our experts keep your community updated and growing with support, development, and consultation."
            contactForm
            methodSubNavIcon="/methods/maintain.svg"
            methodSubNavColor={flairColor}
            subnavTitle="Maintain"
            subnav={subnav}
            wrapChildren
        >
            <MethodsLandingHero
                title="A dedicated team of experts on hand for your community                "
                video="/videos/methods/maintain/hero.mp4"
                videoPoster="/videos/methods/maintain/hero-poster.png"
                videoId="methods-maintain-hero"
                subtitle="On a retainer basis, we’ll be there for your community to offer support, consultation, and handle all the intricacies involved in keeping your community software running smoothly."
            />

            <LandingFeatureGrid
                id={subnav[0].id}
                title="Benefits of a retainer"
                items={[
                    {
                        heading: 'ensure availability',
                        content:
                            'Guarantee a monthly set of hours to offer consultation and technical support.',
                    },
                    {
                        heading: 'discounted rate',
                        content:
                            'With an ongoing partnership, we can offer our services at a discount.',
                    },
                    {
                        heading: 'save your time',
                        content:
                            'We handle the technical nuances of keeping your community up to date and secure.',
                    },
                    {
                        heading: 'on-going development',
                        content:
                            'After the project closes, we can help with improvements and implement new ideas.',
                    },
                    {
                        heading: 'consultation by an expert team',
                        content:
                            'Our team of managers, designers, and developers are on-hand for questions and meetings.',
                    },
                    {
                        heading: 'quarterly audits',
                        content:
                            'We run an audit for performance and progress on key metrics.',
                    },
                ]}
                mb="0"
            />

            <WhatWeOffer id={subnav[1].id} />

            <MethodsCTA
                title="Save your time. Get higher returns. Have the community you deserve."
                flairColor={flairColor}
                bg="primaryBackground"
            />

            <MediaSection
                id={subnav[2].id}
                flipped
                video="/videos/methods/build-method/gantt.mp4"
                videoId="maintain-gantt"
                title="Dedicated project manager 
                to plan requested tasks"
                backgroundColor="secondaryBackground"
            >
                <Paragraph>
                    During the month, our manager will be your main point of
                    contact to answer questions and schedule tasks.
                </Paragraph>
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title: 'Point of contact to answer questions',
                        },
                        {
                            title: 'Assign and schedule tasks',
                        },
                        {
                            title:
                                'Routine meetings to keep the team and you in sync',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            as="li"
                        />
                    ))}
                </Box>
            </MediaSection>

            <Section vertical="md" pt={[0, 0, 0]} wrap bg="secondaryBackground">
                <ClientList
                    title="Valued Partners"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderTop="1px"
                    borderColor="border"
                    pt={['spacing-lg', '40px', 'spacing-xl']}
                />
            </Section>
        </Layout>
    );
}

export const query = graphql`
    query maintainQuery {
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
